import React from "react"
import { navigate } from "gatsby"
import LayoutMinimal from "./Layout/Minimal"
import { XCircleIcon } from "@heroicons/react/solid"
import Button from "./Elements/Button"
function PageNotFound() {
  return (
    <LayoutMinimal
      title="Page Not Found"
      metaTitle="Page Not Found"
      metaDesc="Page Not Found"
    >
      <div className="rounded-md bg-[#fce3e1] py-5 px-6 mb-4">
        <div className="text-reg-15 !leading-[1.5] red pb-1">
          <p>
            It seems that this page doesn't exist, perhaps it's been removed. There's plenty more
            to see at O2O. Why not go back home?
          </p>
        </div>
      </div>
      <Button full red onClick={() => navigate("/")}>
        Back Home
      </Button>
      <div className="mb-[40px]"/>
    </LayoutMinimal>
  )
}

export default PageNotFound
